import React from "react";
import { useAuth } from "../context/AuthContext";
import { useNavigate, Link, useLocation } from "react-router-dom";

const Navbar = () => {
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const handleLogout = () => {
    logout();
    navigate("/login");
  };

  return (
    <nav className="bg-white border-b border-gray-200">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16">
          <div className="flex items-center">
            <div className="flex-shrink-0">
              <span className="text-xl font-bold text-indigo-600">Grupo Rift</span>
            </div>
            <div className="hidden md:ml-6 md:flex md:space-x-8">
              <Link to="/dashboard" className={`inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium ${location.pathname === "/dashboard" ? "border-indigo-500 text-gray-900" : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700"}`}>
                Dashboard
              </Link>
              <Link to="/profile" className={`inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium ${location.pathname === "/profile" ? "border-indigo-500 text-gray-900" : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700"}`}>
                Perfil
              </Link>
              <Link to="/withdrawals" className={`inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium ${location.pathname === "/withdrawals" ? "border-indigo-500 text-gray-900" : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700"}`}>
                Retiros
              </Link>
            </div>
          </div>
          <div className="flex items-center gap-4">
            <div className="hidden md:flex items-center gap-2">
              <div className="h-8 w-8 rounded-full bg-indigo-100 flex items-center justify-center">
                <span className="text-sm font-medium text-indigo-600">{user?.email?.[0]?.toUpperCase()}</span>
              </div>
              <span className="text-sm text-gray-700">{user?.email}</span>
            </div>
            <button onClick={handleLogout} className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-lg text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-colors duration-200">
              Cerrar sesion
            </button>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
