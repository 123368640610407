import React, { useState, useMemo } from "react";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Area } from "recharts";
import { format, subHours, subWeeks, subMonths, fromUnixTime } from "date-fns";

const TIME_RANGES = {
  "24h": {
    label: "24H",
    filter: (timestamp) => timestamp >= subHours(new Date(), 24).getTime() / 1000, // Convert to seconds
    format: "HH:mm",
  },
  "1w": {
    label: "1W",
    filter: (timestamp) => timestamp >= subWeeks(new Date(), 1).getTime() / 1000,
    format: "MMM dd",
  },
  "1m": {
    label: "1M",
    filter: (timestamp) => timestamp >= subMonths(new Date(), 1).getTime() / 1000,
    format: "MMM dd",
  },
};

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="bg-white p-4 rounded-lg shadow-lg border border-gray-100">
        <p className="text-sm text-gray-500 mb-1">{label}</p>
        <p className="text-lg font-semibold text-indigo-600">${payload[0].value.toFixed(2)}</p>
      </div>
    );
  }
  return null;
};

const BalanceChart = ({ balanceHistory = [], initialInvestment, onHoverChange }) => {
  const [timeRange, setTimeRange] = useState("24h");

  const data = useMemo(() => {
    return (
      balanceHistory
        .map((item) => ({
          timestamp: Number(item.timestamp),
          totalUSD: Number(item.totalUSD),
          formattedDate: format(fromUnixTime(Number(item.timestamp)), TIME_RANGES[timeRange].format),
        }))
        // Apply the time range filter
        .filter((item) => TIME_RANGES[timeRange].filter(item.timestamp))
        .sort((a, b) => a.timestamp - b.timestamp)
    );
  }, [balanceHistory, timeRange]);

  const handleMouseMove = (props) => {
    if (props && props.activePayload && props.activePayload[0] && onHoverChange) {
      const currentValue = props.activePayload[0].value;
      const totalChange = currentValue - initialInvestment.amount;
      const totalChangePercentage = (totalChange / initialInvestment.amount) * 100;
      onHoverChange({ value: currentValue, change: totalChange, changePercentage: totalChangePercentage });
    }
  };

  return (
    <div className="p-6">
      <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-6 gap-4">
        <h2 className="text-xl font-semibold text-gray-900">Historial Balance</h2>
        <div className="flex gap-2 p-1 bg-gray-100 rounded-lg">
          {Object.entries(TIME_RANGES).map(([key, { label }]) => (
            <button key={key} onClick={() => setTimeRange(key)} className={`px-4 py-2 rounded-md text-sm font-medium transition-all duration-200 ${timeRange === key ? "bg-white text-indigo-600 shadow-sm" : "text-gray-600 hover:text-gray-900"}`}>
              {label}
            </button>
          ))}
        </div>
      </div>

      <div style={{ width: "100%", height: 400 }}>
        <ResponsiveContainer>
          <LineChart data={data} margin={{ top: 10, right: 30, left: 10, bottom: 0 }} onMouseMove={handleMouseMove} onMouseLeave={() => onHoverChange?.(null)}>
            <defs>
              <linearGradient id="colorUSD" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#4F46E5" stopOpacity={0.1} />
                <stop offset="95%" stopColor="#4F46E5" stopOpacity={0} />
              </linearGradient>
            </defs>
            <CartesianGrid strokeDasharray="3 3" stroke="#E5E7EB" vertical={false} />
            <XAxis dataKey="formattedDate" tick={{ fill: "#6B7280", fontSize: 12 }} tickLine={false} axisLine={{ stroke: "#E5E7EB" }} />
            <YAxis tickFormatter={(value) => `$${value.toFixed(0)}`} tick={{ fill: "#6B7280", fontSize: 12 }} tickLine={false} axisLine={false} width={80} />
            <Tooltip content={<CustomTooltip />} />
            <Area type="monotone" dataKey="totalUSD" stroke="#4F46E5" fill="url(#colorUSD)" strokeWidth={2} />
            <Line
              type="monotone"
              dataKey="totalUSD"
              stroke="#4F46E5"
              dot={false}
              activeDot={{
                r: 6,
                stroke: "#4F46E5",
                strokeWidth: 2,
                fill: "white",
              }}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default BalanceChart;
