import React, { useState, useMemo } from "react";
import { useAuth } from "../context/AuthContext";
import BalanceChart from "./BalanceChart";
import { format } from "date-fns";

const Dashboard = () => {
  const { user } = useAuth();
  const [hoverData, setHoverData] = useState(null);

  const initialInvestment = useMemo(() => {
    if (!user) return null;

    return {
      amount: user.initialInvestment || 0,
      // Convert ISO string to Unix timestamp (seconds)
      timestamp: new Date(user.createdAt).getTime() / 1000,
    };
  }, [user]);

  const stats = useMemo(() => {
    if (!user?.balance || user.balance.length === 0 || !initialInvestment) {
      return {
        currentBalance: 0,
        change24h: 0,
        changePercentage: 0,
        totalChangePercentage: 0,
        totalChange: 0,
      };
    }

    const sortedBalance = [...user.balance].sort((a, b) => a.timestamp - b.timestamp);
    const currentBalance = sortedBalance[sortedBalance.length - 1].totalUSD;
    const balanceToCompare = hoverData ? hoverData.value : currentBalance;

    const totalChange = balanceToCompare - initialInvestment.amount;
    const totalChangePercentage = (totalChange / initialInvestment.amount) * 100;

    const oneDayAgo = Date.now() / 1000 - 86400;
    const last24h = sortedBalance.filter((b) => b.timestamp >= oneDayAgo);

    let change24h = 0;
    let changePercentage = 0;

    if (last24h.length >= 2) {
      change24h = currentBalance - last24h[0].totalUSD;
      changePercentage = (change24h / last24h[0].totalUSD) * 100;
    }

    return {
      currentBalance,
      change24h,
      changePercentage,
      totalChangePercentage,
      totalChange,
    };
  }, [user?.balance, hoverData, initialInvestment]);

  const formatUSD = (value) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value);
  };

  const formatPercentage = (value) => {
    return `${value > 0 ? "+" : ""}${value.toFixed(2)}%`;
  };

  if (!user?.balance || !initialInvestment) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-10 w-10 border-b-2 border-indigo-600"></div>
      </div>
    );
  }
  return (
    <div className="min-h-screen bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="grid gap-6">
          {/* Initial Investment Info */}
          <div className="bg-white rounded-xl shadow-sm p-6 border border-gray-100">
            <div className="flex items-baseline gap-2">
              <span className="text-sm text-gray-500">Inversion Inicial:</span>
              <span className="font-medium">{formatUSD(initialInvestment.amount)}</span>
              <span className="text-sm text-gray-500">en</span>
              <span className="font-medium">{format(initialInvestment.timestamp * 1000, "MMMM d, yyyy")}</span>
            </div>
          </div>

          {/* Stats Cards */}
          <div className="bg-white rounded-xl shadow-sm p-6 border border-gray-100">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              {/* Current Balance Card */}
              <div className="p-6 bg-gradient-to-br from-indigo-50 to-indigo-100 rounded-lg">
                <h3 className="text-sm font-medium text-indigo-600 mb-1">Balance Actual</h3>
                <p className="text-2xl font-bold text-gray-900">{formatUSD(stats.currentBalance)}</p>
              </div>

              {/* 24h Change Card */}
              <div className={`p-6 bg-gradient-to-br ${stats.changePercentage >= 0 ? "from-emerald-50 to-emerald-100" : "from-red-50 to-red-100"} rounded-lg`}>
                <h3 className={`text-sm font-medium ${stats.changePercentage >= 0 ? "text-emerald-600" : "text-red-600"} mb-1`}>24h Cambio</h3>
                <div className="flex items-baseline gap-2">
                  <p className="text-2xl font-bold text-gray-900">{formatPercentage(stats.changePercentage)}</p>
                  <p className="text-sm text-gray-600">({formatUSD(stats.change24h)})</p>
                </div>
              </div>

              {/* Total/Point Return Card */}
              <div className={`p-6 bg-gradient-to-br ${stats.totalChangePercentage >= 0 ? "from-emerald-50 to-emerald-100" : "from-red-50 to-red-100"} rounded-lg transition-colors duration-200`}>
                <h3 className={`text-sm font-medium ${stats.totalChangePercentage >= 0 ? "text-emerald-600" : "text-red-600"} mb-1`}>{hoverData ? "Retorno en Punto Seleccionado" : "Retorno Total"}</h3>
                <div className="flex items-baseline gap-2">
                  <p className="text-2xl font-bold text-gray-900">{formatPercentage(stats.totalChangePercentage)}</p>
                  <p className="text-sm text-gray-600">({formatUSD(stats.totalChange)})</p>
                </div>
              </div>
            </div>
          </div>

          {/* Chart */}
          <div className="bg-white rounded-xl shadow-sm border border-gray-100">
            <BalanceChart balanceHistory={user?.balance || []} initialInvestment={initialInvestment} onHoverChange={setHoverData} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
