import React, { useState } from "react";
import { useAuth } from "../context/AuthContext";
import axiosInstance from "../utils/axiosInstance";

const ACCOUNT_TYPES = [
  { value: "Corriente", label: "Cuenta Corriente" },
  { value: "Ahorro", label: "Cuenta de Ahorro" },
];

const Retiros = () => {
  const { user, fetchUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({ type: "", text: "" });
  const [hasSubmitted, setHasSubmitted] = useState(false);

  const [formData, setFormData] = useState({
    amount: "",
    bankAccType: "",
    bankName: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setHasSubmitted(true);

    try {
      await axiosInstance.put("/v1/users/initiateWithdrawal", formData);
      setMessage({ type: "success", text: "Solicitud de retiro enviada exitosamente!" });
      setFormData({
        amount: "",
        bankAccType: "",
        bankName: "",
      });
      await fetchUser();
    } catch (error) {
      setMessage({
        type: "error",
        text: error.response?.data?.message || "Error al enviar la solicitud de retiro",
      });
      setHasSubmitted(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 py-8">
      <div className="max-w-2xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="bg-white rounded-xl shadow-sm p-8 border border-gray-100">
          <h2 className="text-2xl font-bold text-gray-900 mb-6">Solicitud de Retiro</h2>

          {message.text && <div className={`mb-4 p-4 rounded-lg ${message.type === "success" ? "bg-green-50 text-green-700" : "bg-red-50 text-red-700"}`}>{message.text}</div>}

          {user?.withdrawalPending && !hasSubmitted && <div className="mb-6 p-4 rounded-lg bg-yellow-50 text-yellow-700">Ya tienes una solicitud de retiro pendiente.</div>}

          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label htmlFor="amount" className="block text-sm font-medium text-gray-700">
                Cantidad
              </label>
              <div className="mt-1 relative rounded-md shadow-sm">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <span className="text-gray-500 sm:text-sm">$</span>
                </div>
                <input type="number" id="amount" name="amount" value={formData.amount} onChange={handleChange} className="mt-1 block w-full pl-7 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500" placeholder="0.00" min="0" step="0.01" required />
              </div>
            </div>

            <div>
              <label htmlFor="bankAccType" className="block text-sm font-medium text-gray-700">
                Tipo de Cuenta
              </label>
              <select id="bankAccType" name="bankAccType" value={formData.bankAccType} onChange={handleChange} className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500" required>
                <option value="">Seleccionar tipo de cuenta</option>
                {ACCOUNT_TYPES.map((type) => (
                  <option key={type.value} value={type.value}>
                    {type.label}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <label htmlFor="bankName" className="block text-sm font-medium text-gray-700">
                Número de Cuenta
              </label>
              <input type="text" id="bankName" name="bankName" value={formData.bankName} onChange={handleChange} className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500" required />
            </div>

            <div className="flex justify-end">
              <button
                type="submit"
                disabled={loading || user?.withdrawalPending}
                className={`px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-colors duration-200
                                    ${loading || user?.withdrawalPending ? "opacity-50 cursor-not-allowed" : ""}`}
              >
                {loading ? (
                  <span className="flex items-center">
                    <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    Procesando...
                  </span>
                ) : (
                  "Pedir Retiro"
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Retiros;
